body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
100	Thin (Hairline)
200	Extra Light (Ultra Light)
300	Light
400	Normal
500	Medium
600	Semi Bold (Demi Bold)
700	Bold
800	Extra Bold (Ultra Bold)
900	Black (Heavy)
*/

@font-face {
  font-family: "Horizon";
  src: local("Horizon"), url(./fonts/Horizon.otf) format("opentype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 200;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-EL.ttf) format("truetype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 300;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-L.ttf) format("truetype");
}
@font-face {
  font-family: "GenRyuMin";
  font-weight: 400;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-R.ttf) format("truetype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 500;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-M.ttf) format("truetype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 600;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-SB.ttf) format("truetype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 700;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-B.ttf) format("truetype");
}

@font-face {
  font-family: "GenRyuMin";
  font-weight: 900;
  src: local("GenRyuMin"), url(./fonts/GenRyuMinTW-H.ttf) format("truetype");
}
